<template>
  <!--begin::RegisterKO-->
  <div class="login-form text-center">
    <h2 class="text-danger my-12">
      <inline-svg class="mr-3" src="/media/icons/cross-danger.svg" />
      {{ $t("REGISTER.FINISHED_KO") }}
    </h2>

    <button
      class="btn btn-pill btn-white button-form button-form-primary my-12"
      @click="goToLogin"
    >
      <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
      {{ $t("GENERIC.BACK") }}
    </button>
  </div>
  <!--end::RegisterKO-->
</template>

<script>
export default {
  name: "RegisterKO",

  mounted() {
    // Set parent layout content
    this.$emit(
      "layout-login-aside-background",
      process.env.BASE_URL + "media/bg/imagen-bienvenido.jpg"
    );
    this.$emit("layout-login-aside-title", this.$i18n.t("REGISTER.TITLE"));
    this.$emit("layout-login-aside-description", "");
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    }
  }
};
</script>
